import type { ICustomImage } from '@filmweb/sanity-types';
import CmsImageForAssetRef from 'src/components/images/CmsImageForAssetRef';
import { doCrop } from 'src/components/images/imageFunctions';
import { urlForAsset } from 'src/lib/client/imageUrls';
import styled from "src/lib/styles/css";
import NextImage from "next/image";
import type { ImageHotspot } from 'sanity';

//#region [Props]
type PictureProps = {
	className?: string;
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function Picture({ className, children }: PictureProps) {
	return <picture className={className}>
		{children}
	</picture>;
}
//#endregion

//#region [Props]
type PictureFallbackImageProps = {
	className?: string;
	cmsImage?: ICustomImage; // will be used if both cmsImage and imageUrl is specified
	imageUrl?: string;
	alt: string;
	width?: number | string;
	height?: number | string;
    hotspot?: ImageHotspot;
};
//#endregion

//#region [Component]
export function PictureFallbackImage({ className, cmsImage, imageUrl, alt, width, height, hotspot }: PictureFallbackImageProps) {
	if (!cmsImage && !imageUrl) return null;
	if (cmsImage) {
		// use cms image if present
		return (<CmsImageForAssetRef
			assetRef={cmsImage.asset?._id ?? cmsImage.asset?._ref as string}
			alt={alt}
			width={width}
			className={className}
            hotspot={hotspot}
			unoptimized
		/>);
	}
	// fallback to imageUrl
	return <SImgFromUrl src={imageUrl!} width={width ? Number(width) : undefined} height={height ? Number(height) : undefined} alt={alt} unoptimized />

}
//#endregion

//#region [Styles]
const SImgFromUrl = styled(NextImage)`
	height: auto;
`;
//#endregion

//#region [Props]
type PictureSourceProps = {
	media?: string;
	imgUrl?: string;
	cmsImage?: ICustomImage; //  will be used if both cmsImage and imgUrls is specified
	width: number;
	aspect?: number;
	hotspot?: ImageHotspot;
};
//#endregion

//#region [Component]
export function PictureSource({ media, imgUrl, cmsImage, width, aspect, hotspot }: PictureSourceProps) {
	if (!cmsImage && !width && !imgUrl) {
		return null;
	}

	if (cmsImage) {
		let imgBuilder = urlForAsset(cmsImage.asset?._id ?? cmsImage.asset?._ref as string).auto("format").width(width);
		if (aspect) {
			imgBuilder = imgBuilder.height(Math.floor(width / aspect));
		}
		imgBuilder = doCrop(imgBuilder, hotspot);

		const cmsImageUrl = imgBuilder.url();
		return <source media={media} srcSet={`${cmsImageUrl} ${width}w`} />
	}

	return <source media={media} srcSet={`${imgUrl} ${width}w`} />
}
//#endregion