"use client"

import { createContext, useContext, useState } from "react";

type TrailerContextType = {
	isPlaying: boolean;
	setIsPlaying: (isPlaying: boolean) => void;
}

const TrailerContext = createContext<TrailerContextType>({
	isPlaying: false,
	setIsPlaying: (isPlaying) => { }
});

//#region [Props]
type TrailerWrapperProps = {
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function TrailerWrapper({ children }: TrailerWrapperProps) {
	const [isPlaying, setIsPlaying] = useState(false);
	return <TrailerContext.Provider value={{ isPlaying, setIsPlaying }}>{children}</TrailerContext.Provider>;
}
//#endregion

export function useTrailerContext() {
	return useContext(TrailerContext);
}
