import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';

//#region [Props]
type FilmTitleProps = {
	title: string;
	length?: string;
	genres?: string[];
	ageRating?: string;
};
//#endregion

//#region [Component]
export default function FilmTitle({ title, length, genres, ageRating }: FilmTitleProps) {
	let len = length;
	if( length && /^\d+$/.test(length) ) {
		const lenNum = Number(length);
		if( lenNum > 60) {
			len = `${Math.floor(lenNum / 60).toFixed(0)} t ${lenNum % 60} m`;
		}
	}

	return <STitleWrapper>
		<SFilmTitle>{title}</SFilmTitle>
		<STagLine>
			{ageRating && <SAgeRating aria-label='Anbefalt aldersgrense'>{ageRating}</SAgeRating>}
			{len && <SLength>{len}</SLength>}
			<SGenres>{genres?.join(" / ")}</SGenres>
		</STagLine>
	</STitleWrapper>;
}
//#endregion

//#region [Styles]
const STitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: var(--textcolor);
	justify-content: center;
	padding-bottom: 30px;

	@media ${theme.mq.desktop} {
		justify-content: left;
		padding: 10px 50px 0 0;
	}
`;

const SFilmTitle = styled.h1`
	line-height: 40px;
	text-align: center;
	font-size: ${theme.fonts.tittelM.size};
	font-weight: ${theme.fonts.tittelM.weight};

	@media ${theme.mq.desktop} {
		font-size: ${theme.fonts.tittelXL.size};
		font-weight: ${theme.fonts.tittelXL.weight};
		line-height: 72px;
		text-align: left;
		order: 0;
	}
`;

const STagLine = styled.div`
	display: flex;
	justify-content: center;
	gap: 15px;
	line-height: 40px;

	@media ${theme.mq.desktop} {
		width: 100%;
		justify-content: left;
		font-size: 18px;
		line-height: 23px;
	}
`;

const cssData = css`
	font-size: ${theme.fonts.tittelXXS.size};
	font-weight: ${theme.fonts.tittelXXS.weight};
`

const SAgeRating = styled.div`
	${cssData}
`;

const SLength = styled.div`
	${cssData}
`;

const SGenres = styled.div`
	${cssData}

	display: none;

	@media ${theme.mq.desktop} {
		display: block;
	}
`;
//#endregion