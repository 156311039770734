import { Premiere } from '@filmweb/sanity-types';
import styled from 'src/lib/styles/css';
import { format, parse } from "date-fns";
import locale from "date-fns/locale/nb";

//#region [Props]
type FilmPremiereProps = {
	className?: string;
	premiere?: Premiere;
	customPremiereLabel?: string;
};
//#endregion

//#region [Component]
export default function FilmPremiere({ className, premiere, customPremiereLabel }: FilmPremiereProps) {
	if (!premiere) {
		return null;
	}

	let premLabel = "";
	let prem: string | null = null;
	if (premiere!.premiereDate) {
		premLabel = "Premiere";
		let d = parse(premiere!.premiereDate, "yyyy-MM-dd", new Date());
		prem = format(d, "dd.MM.yyyy", { locale });
	} else if (premiere?.expectedPremiereDate) {
		premLabel = "Antatt premiere";
		let d = parse(premiere!.expectedPremiereDate, "yyyy-MM-dd", new Date());
		prem = format(d, "MMMM yyyy", { locale });
	}

	if (!prem) {
		return null;
	}

	premLabel = customPremiereLabel ?? premLabel;

	return <div className={className}>
		<span>{premLabel}:</span> {prem}
	</div>;
}
//#endregion

//#region [Styles]

//#endregion