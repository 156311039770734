import WatchlistButton from 'src/components/filmwebid/WatchlistButton';
import withWatchlistState from 'src/components/filmwebid/withWatchlistState';
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
//#region [Styles]
const SNoProviders = styled.div`
	border: 2px solid ${theme.textColor};
	border-radius: 8px;
	color: ${theme.textColor};
	background: ${theme.buttonColor};
	padding: 20px;

	@media ${theme.mq.desktop} {
		padding: 52px;
	}

	display: flex;
`;

const SP = styled.p`
	margin: 0;

	&:first-of-type {
		font-size: 24px;
		margin-bottom: 0.5em;
	}
`;
//#endregion

//#region [Props]
type NoProvidersProps = {
	streamingId: number;
};
//#endregion

//#region [Component]
const WLWatchlistButton = withWatchlistState(WatchlistButton)

export default function NoProviders({ streamingId }: NoProvidersProps) {
	return <SNoProviders>
		<WLWatchlistButton streamingId={streamingId} />
		<div>
			<SP>Ooops, vi finner ingen steder du kan streame denne akkurat nå.</SP>
			<SP>Legg den i din huskeliste, så varsler vi deg når den blir tilgjengelig.</SP>
		</div>
	</SNoProviders>;
}
//#endregion