import imageUrlBuilder from "@sanity/image-url";
import { doCrop } from "src/components/images/imageFunctions";
import { envConfig } from "src/lib/client/envConfig";
import { ImageLoader } from "next/image";
import { ImageHotspot } from "sanity";

const imgUrlBuilder = imageUrlBuilder({ projectId: envConfig.NEXT_PUBLIC_KINOKLUBB_SANITY_PROJECTID, dataset: envConfig.NEXT_PUBLIC_KINOKLUBB_SANITY_DATASET });

export function urlForAsset(assetRef: string): ReturnType<typeof imageUrlBuilder> {
	return imgUrlBuilder.image(assetRef);
}

export function getImageLoader(cropAspect: number, hotspot?: ImageHotspot): ImageLoader {
	let imgLoader: ImageLoader = (p) => {
		let builder = urlForAsset(p.src);
		builder = doCrop(builder, hotspot).width(p.width).height(Math.floor(p.width / cropAspect));
		const url = builder.auto("format").url();
        return url;
		//return `/_next/image?url=${encodeURIComponent(url)}&w=${p.width}&q=${p.quality}`;
	};


	return imgLoader;
}