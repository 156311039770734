"use client"
import Button from "src/components/button/Button";
import { useMovieActivityContext } from "src/components/film/filmwebid/MovieActivityContext";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import SpeechBubbleSvg from "src/components/svg/SpeechBubbleSvg";
import theme from 'src/components/themes/DefaultTheme';
import { useUserContext } from "src/lib/contexts/UserContext";
import IMovieReview from "src/lib/filmwebid/IMovieReview";
import useFilmReviewList from "src/lib/filmwebid/useFilmReviewList";
import styled, { css } from 'src/lib/styles/css';
import { forwardRef, useEffect, useMemo, useState } from "react";

import dynamic from "next/dynamic";

const ReviewPanel = dynamic(() => import('src/components/filmwebid/ReviewPanel'), {
	loading: () => <p>Loading...</p>,
	ssr: false
});

const ReviewList = dynamic(() => import('src/components/film/filmwebid/ReviewList'), {
	loading: () => <p>Loading...</p>,
	ssr: false
});


//#region [Props]
type UserReviewsProps = {
	edi?: string;
	streamingId?: number;
	title: string;
};
//#endregion

//#region [Component]
const UserReviews = forwardRef<HTMLElement, UserReviewsProps>(({ edi, streamingId, title }, ref) => {
	const movieActivityContext = useMovieActivityContext();
	const userContext = useUserContext();
	const [_reviewList, totalReviews, isFetchingList, fetchNext] = useFilmReviewList(edi, streamingId);
	const [showAll, setShowAll] = useState(false);
	const [showReviewPanel, setShowReviewPanel] = useState(false);

	const reviewList: IMovieReview[] | undefined = useMemo(() => {
		if (!isFetchingList) {
			if (movieActivityContext.activity?.movieUserInteraction?.review) {
				// find the review in the review list if it exists
				const serverReview = _reviewList?.find(sr => sr.reviewId === movieActivityContext.activity?.movieUserInteraction?.reviewId);
				if (serverReview) {
					// there exists a review on the server
					if (serverReview.reviewText !== movieActivityContext.activity.movieUserInteraction.review) {
						// user has updated his review. We don't really need to load the list from the server since we already have it
						return [{ ...serverReview, reviewText: movieActivityContext.activity.movieUserInteraction.review }, ..._reviewList.filter(r => r.reviewId !== serverReview.reviewId)];
					}
				} else {
					// this is a new review, so load the whole list again.
					let mr: IMovieReview = {
						reviewId: movieActivityContext.activity.movieUserInteraction.reviewId!,
						reviewText: movieActivityContext.activity.movieUserInteraction.review,
						rating: movieActivityContext.activity.movieUserInteraction.rating,
						userName: userContext.userProfile?.userName!,
						hasUploadedPicture: !!userContext.userProfile?.picture,
						pictureUrl: userContext.userProfile?.picture,
						userId: userContext.userProfile?.userId!

					};
					return [mr, ...(_reviewList ?? [])];
				}
			} else {
				const userId = userContext.getUserId();
				// the user has no review. If he has deleted it, make sure it is removed from server list
				return _reviewList?.filter(sr => sr.userId !== userId) ?? [];
			}

		}
		return _reviewList; // default
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_reviewList, isFetchingList, movieActivityContext.activity?.movieUserInteraction?.review, movieActivityContext.activity?.movieUserInteraction?.reviewId, movieActivityContext.activity?.movieUserInteraction?.rating]);

	useEffect(() => {
		setShowAll(false);
	}, [edi, streamingId]);

	const _showReviewPanel = () => {
		if (userContext.isAuthenticated) {
			setShowReviewPanel(true);
		} else {
			userContext.doLogin();
		}
	};

	const onReviewPanelClose = (doUpdate: boolean) => {
		setShowReviewPanel(false);
		if (doUpdate) {
			movieActivityContext.activityUpdater();
		}
	}

	return <SUserReviewsContainer ref={ref}>
		<SHeading>Anmeldelser</SHeading>

		<SReviews>
			<ReviewList reviewList={reviewList} edi={edi} streamingId={streamingId} setShowReviewPanel={setShowReviewPanel} showAll={showAll} />
			{(reviewList?.length ?? 0) === 0 && <SNoReviews>Foreløpig har ingen anmeldt {title}</SNoReviews>}
			{(isFetchingList || movieActivityContext.isLoading) && <LoadingIndicator position="InlineCenter" />}
		</SReviews>

		{!isFetchingList && <SActions>
			{/** Offset by 2 here because 2 will be shown on mobile, but 4 on desktop, but 4 are loaded initially  */}
			{(reviewList?.length ?? 0) > 2 && !showAll && <SSeeMoreButton
				$numReviews={reviewList?.length!}
				disabled={movieActivityContext.isLoading}
				text="Se flere"
				onClick={() => { setShowAll(true); fetchNext(totalReviews); }} />}
		</SActions>}
		<SWriteReviewButton
			disabled={movieActivityContext.isLoading}
			onClick={_showReviewPanel}
			text="Skriv anmeldelse"
			icon={<SpeechBubbleSvg selected={!!movieActivityContext.activity?.movieUserInteraction?.review} />}
		/>
		{showReviewPanel && <ReviewPanel
			onClose={onReviewPanelClose}
			streamingId={streamingId}
			edi={edi}
			reviewId={movieActivityContext?.activity?.movieUserInteraction?.reviewId}
			userRating={movieActivityContext?.activity?.movieUserInteraction?.rating}
			userReview={movieActivityContext?.activity?.movieUserInteraction?.review}
			/>}
	</SUserReviewsContainer>;

});

UserReviews.displayName = "UserReviews";



export default UserReviews;
//#endregion


//#region [Styles]
const SUserReviewsContainer = styled.section`
	margin: 0 ${theme.maxContentPadding} 35px ${theme.maxContentPadding};
	@media ${theme.mq.desktop} {
		grid-area: reviews;
		margin-bottom: 0;
	}

	color: var(--textcolor);
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 20px;
`;

const SHeading = styled.h4`
	font-size: ${theme.fonts.tittelXS.size};
	flex: 0 0 100%;
	order: 0;

	@media ${theme.mq.desktop} {
		flex: 0 0 auto;
		font-size: ${theme.fonts.tittelS.size};
		align-self: center;
	}
`;



const SReviews = styled.div`
	flex: 1 0 100%;
	order: 2;
	gap: 25px;
	flex-wrap: wrap;
	display: flex;
	transition: opacity 0.5s;
`;



const SNoReviews = styled.div`
	font-size: ${theme.fonts.tittelXXXS.size};
	@media ${theme.mq.desktop} {
		font-size: ${theme.fonts.tittelXS.size};
	}
`;

const SActions = styled.div`
	order: 3;
	@media ${theme.mq.desktop} {
		width: 100%;
		text-align: center;
	}
`;

const SWriteReviewButton = styled(Button)`
	margin-left: auto;
	order: 4;
	@media ${theme.mq.desktop} {
		margin-left: 0;
		order: 1;
		flex: 0 1 auto;
	}
`;

type SSeeMoreButtonProps = {
	$numReviews: number;
}

const SSeeMoreButton = styled(Button) <SSeeMoreButtonProps>`
	display: none;
	${props => props.$numReviews > 2 ? css`
		display: inline-flex;
	`: ""}

	@media ${theme.mq.desktop} {
		display: none;

		${props => props.$numReviews >= 6 ? css`
			display: inline-flex;
		`: ""}
	}
`;
//#endregion