import { toPlainText } from '@portabletext/react';
import { urlForAsset } from 'src/lib/client/imageUrls';
import { StreamingContentType } from 'src/lib/movieinfo/movieinfotypes';
import { IFilmomtale } from 'src/lib/types/Filmomtale';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { parse, addMonths, isFuture } from "date-fns";
import locale from "date-fns/locale/nb";

//#region [Props]
type FilmSeoProps = {
    document?: IFilmomtale | null
    film?: StreamingContentType | null;
    isStreamingContext: boolean;
};
//#endregion

//#region [Component]
export default function FilmSeo({ document, film, isStreamingContext }: FilmSeoProps) {
    const router = useRouter();

    if (!document && !film) return null;

    const title = isStreamingContext ? film?.title : document?.title;
    const year: string | number | undefined = isStreamingContext ? film?.year : document?.year;

    const metaTitle = isStreamingContext ? `${title} - strømme online - Streamingguide` : `${title}${year ? ` - ${year}` : ""} - Filmweb`;

    let metaDescription = !isStreamingContext ? document?.oneliner?.trim() : film?.synopsisIngress?.trim();
    if (!isStreamingContext && !metaDescription && document?.ingress) {
        metaDescription = toPlainText(document.ingress);
    }

    const url = router.asPath.split("?", 1)[0];

    let imageUrl: string | null = null;
    if (!isStreamingContext && document?.mainImageV2) {
        imageUrl = urlForAsset(document!.mainImageV2.asset?._id ?? document!.mainImageV2.asset?._ref).auto("format").width(1080).url();
    } else if (isStreamingContext && (film?.imagesWide?.length ?? 0) > 0) {
        imageUrl = film!.imagesWide!.sort((a, b) => (a!.width < b!.width ? 1 : -1))[0]!.url;
    }

    const canonical = findCanonical(document, film);

    return <Head>
        <meta key="ogTitle" property="og:title" content={metaTitle} />

        {metaDescription && <>
            <meta key="ogDescrtiption" property="og:description" content={metaDescription} />
            <meta key="metaDescription" name="description" content={metaDescription} />
        </>}

        {canonical && <link key="canonical" rel="canonical" href={canonical} />}

        <meta key="ogUrl" property="og:url" content={`https://www.filmweb.no${url}`} />

        {imageUrl && <meta key="ogImage" property='og:image' content={imageUrl} />}

        <meta key="ogType" property="og:type" content="article" />
        <title key="title">{metaTitle}</title>

    </Head>;
}
//#endregion

function findCanonical(cmsFilmomtale?: IFilmomtale | null, strFilmomtale?: StreamingContentType | null): string | null {
    // we have a cinema movie and a streaming movie.
    // Point the canonical to the cinema movie if the movie has not had it's premiere or it premiered less than 5 months ago
    if (cmsFilmomtale && strFilmomtale) {
        let canonical = `https://www.filmweb.no/streamingguide/film/${strFilmomtale.id}`;
        if (cmsFilmomtale.premiere && cmsFilmomtale.premiere.noCinemaRelease !== true && (cmsFilmomtale.premiere.premiereDate || cmsFilmomtale.premiere.expectedPremiereDate)) {
            const premDate = parse((cmsFilmomtale.premiere.premiereDate ?? cmsFilmomtale.premiere.expectedPremiereDate)!, "yyyy-MM-dd", new Date(), { locale });
            if (isFuture(addMonths(premDate, 5))) {
                canonical = `https://www.filmweb.no/film/${cmsFilmomtale.mainVersionEDI}`;
            }
        }

        return canonical;
    }
    return null;
}