
import useUserApi from "src/lib/filmwebid/useUserApi";
import { useCallback, useState, useEffect } from "react";

type UseRatingProps = {
	edi?: string | null;
	streamingId?: number | null;
	onComplete?: () => void;
	initialValue?: number;
}

export default function useRating({ edi = null, streamingId = null, initialValue, onComplete }: UseRatingProps):
	[doRating: (ratingValue: number | undefined) => void, isWorking: boolean, rating: number | undefined, isDone: boolean] {
	const updater = useUserApi();
	const [isWorking, setIsWorking] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [payload, setPayload] = useState<{ ratingValue: number } | null>(null);
	const [rating, setRating] = useState(initialValue);

	useEffect(() => {
		if (payload !== null) {
			const _action = async () => {
				if (payload?.ratingValue) {
					setRating(payload.ratingValue);
					await updater("/api/Ratings", { method: "POST", allowAnon: false, body: { EDI: edi, streamingId, source: window?.location?.hostname, rating: payload.ratingValue } })
				} else {
					setRating(undefined);
					await updater("/api/Ratings", { method: "DELETE", allowAnon: false, params: { edi, streamingId } })
				}
				setIsWorking(false);
				setIsDone(true);
				onComplete?.();
			};
			setIsWorking(true);
			setIsDone(false);
			_action();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload, streamingId, edi]);

	const doRating = useCallback((ratingValue: number | undefined) => {
		if ((ratingValue ?? -1) >= 0) {
			setPayload({ ratingValue: ratingValue! });
		} else {
			setPayload(null);
		}
	}, []);

	return [doRating, isWorking, rating, isDone];
} 2