import { Review } from '@filmweb/sanity-types';
import DiceRatings from 'src/components/diceratings/DiceRatings';
import UserRatings from "src/components/film/filmwebid/UserRatings";
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';

//#region [Props]
type RatingsProps = {
	reviews?: Review[];
	className?: string;
	edi?: string;
	streamingId?: number;
	hideUserRatings?: boolean;

};
//#endregion

//#region [Component]
export default function Ratings({ reviews, className, edi, streamingId, hideUserRatings = false }: RatingsProps) {
	return <SRatingsContainer className={className}>
		{!hideUserRatings && <UserRatings edi={edi} streamingId={streamingId} />}
		<SDiceRatings reviews={reviews} />
	</SRatingsContainer>;
}
//#endregion

//#region [Styles]
const SRatingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 ${theme.maxContentPadding} 35px ${theme.maxContentPadding};
	@media ${theme.mq.desktop} {
		grid-area: ratings;
		margin-bottom: 0;
	}
`;

const SDiceRatings = styled(DiceRatings)`
	width: 100%;
`;
//#endregion