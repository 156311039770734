import Ingress, { CSSIngressBlock } from "src/components/richtext/Ingress";
import MainText, { CSSMainTextBlock } from "src/components/richtext/MainText";
import theme from "src/components/themes/DefaultTheme";
import { StreamingContentType } from "src/lib/movieinfo/movieinfotypes";
import styled from 'src/lib/styles/css';
import { IFilmomtale } from "src/lib/types/Filmomtale";

//#region [Props]
type FilmTextsProps = {
	cmsDocument?: IFilmomtale | null;
	streamingData?: StreamingContentType | null;
	isStreamingContext?: boolean;
};
//#endregion

//#region [Component]
export default function FilmTexts({ cmsDocument, streamingData, isStreamingContext = false }: FilmTextsProps) {
	let hasCmsText = (cmsDocument?.ingress?.length ?? 0) > 0 || (cmsDocument?.bodyText?.length ?? 0) > 0;
	let hasStreamingText = streamingData?.synopsisIngress || streamingData?.synopsisBodyText;
	let useCmsText = !isStreamingContext;

	if (!hasStreamingText && hasCmsText) {
		useCmsText = true;
	}
	if (hasStreamingText && !hasCmsText) {
		useCmsText = false;
	}

	if (hasStreamingText && hasCmsText && isStreamingContext) {
		useCmsText = false;
	}

	if (!hasCmsText && !hasStreamingText) {
		return null;
	}

	return <SText>
		{useCmsText && <>
			<Ingress ingress={cmsDocument!.ingress} />
			<MainText mainText={cmsDocument!.bodyText} />
		</>}
		{!useCmsText && <>
			{streamingData?.synopsisIngress && <SStreamingIngress>
				<SPreserveWhiteSpaceParagraph>{streamingData.synopsisIngress}</SPreserveWhiteSpaceParagraph>
			</SStreamingIngress>}
			{streamingData?.synopsisBodyText && <SStreamingBodyText>
				<SPreserveWhiteSpaceParagraph>{streamingData.synopsisBodyText}</SPreserveWhiteSpaceParagraph>
			</SStreamingBodyText>}
		</>}

	</SText>;
}
//#endregion

//#region [Styles]
const SText = styled.div`
	margin: 0 ${theme.maxContentPadding} 35px ${theme.maxContentPadding};
	@media ${theme.mq.desktop} {
		grid-area: text;
	}
`;

const SStreamingIngress = styled.div`
	${CSSIngressBlock}
`;

const SStreamingBodyText = styled.div`
	${CSSMainTextBlock}
`;

const SPreserveWhiteSpaceParagraph = styled.p`
    white-space: pre-line;
`;
//#endregion