"use client"
import { ICustomImage } from '@filmweb/sanity-types';
import { useTrailerContext } from 'src/components/film/TrailerWrapper';
import MissingImage from "src/components/images/MissingImage";
import Picture, { PictureFallbackImage, PictureSource } from 'src/components/images/Picture';
import theme from 'src/components/themes/DefaultTheme';
import { ImageVersionType } from 'src/lib/movieinfo/movieinfotypes';
import styled, { css } from 'src/lib/styles/css';
import { useState } from 'react';

//#region [Props]
type FilmTopImageProps = {
	cmsImage?: ICustomImage;
	cmsPosterImage?: ICustomImage;
	streamingImages?: ImageVersionType[];
	streamingPosterImages?: ImageVersionType[];
	fallbackAltText: string;
	isStreamingContext: boolean;
	sanityPosterUrl?: string;
	sanityWideImageUrl?: string;
};
//#endregion

//#region [Component]
export default function FilmTopImage({ cmsImage, cmsPosterImage, streamingImages, streamingPosterImages, fallbackAltText, isStreamingContext, sanityPosterUrl, sanityWideImageUrl }: FilmTopImageProps) {
	const trailerContext = useTrailerContext();
	const [isExpanded, setIsExpanded] = useState(false);
	const streamingPosterImageFallback = streamingPosterImages?.sort((a, b) => (a!.width < b!.width ? -1 : 1)).find(img => img.width >= 640)?.url;
	const _streamingImages = streamingImages?.sort((a, b) => (a!.width > b!.width ? -1 : 1)).filter(img => img.width > 640);

	// NOTE: We might have sanity images in streaming mode if the distributor has approved of using cinema materiel when showing streaming,
	// but we will not using streaming materiel if we are missing cinema materiel
	const hasMobileImage = ((isStreamingContext && (
		!!(sanityPosterUrl || cmsPosterImage) || !!streamingPosterImageFallback
	)) ||
		(!isStreamingContext && (
			!!cmsPosterImage || !!sanityPosterUrl
		)));

	const hasDesktopImage = ((isStreamingContext && (
		!!(sanityWideImageUrl || cmsImage) || !!streamingImages
	)) ||
		(!isStreamingContext && (
			!!cmsImage || !!sanityWideImageUrl
		)));

	console.debug("Has mobile image:", hasMobileImage, ", Has desktop image:", hasDesktopImage);

	// NOTE: <picture> will not show anything at all if it does not have an <img> fallback!
	return <STopImageContainer $isUsingWideFallback={hasDesktopImage && !hasMobileImage} onClick={() => setIsExpanded(!isExpanded)} $isExpanded={isExpanded} $isShowingTrailer={trailerContext.isPlaying} title="Klikk for å se hele bildet">
		{!hasMobileImage && !hasDesktopImage && <SMissingMobileImage aspect="112/156" missingText="Mangler plakat" />}

		{(hasMobileImage || hasDesktopImage) && <Picture>
			{isStreamingContext && <>
				{hasDesktopImage && <>
					{(sanityWideImageUrl || cmsImage) && <>
						<PictureSource media="(min-width: 1600px)" imgUrl={sanityWideImageUrl} cmsImage={cmsImage} width={2000} aspect={2} hotspot={cmsImage?.asset?.hotspot} />
						<PictureSource media="(min-width: 1201px)" imgUrl={sanityWideImageUrl} cmsImage={cmsImage} width={1400} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
						<PictureSource media="(min-width: 641px)" imgUrl={sanityWideImageUrl} cmsImage={cmsImage} width={1200} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
						<PictureSource media={theme.mq.desktop} imgUrl={sanityWideImageUrl} cmsImage={cmsImage} width={640} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
					</>}
					{!(sanityWideImageUrl || cmsImage) && (_streamingImages?.length ?? 0) > 0 && <>
						{_streamingImages?.map((img, idx) => <PictureSource media={idx === _streamingImages.length - 1 ? theme.mq.desktop : `(min-width: ${img.width}px)`} key={idx} imgUrl={img.url} width={img.width} />)}
					</>}
				</>}
				{hasMobileImage && <>
					{(sanityPosterUrl || cmsPosterImage) && <PictureFallbackImage alt={cmsImage?.asset?.altText ?? fallbackAltText}
						imageUrl={sanityPosterUrl}
						cmsImage={cmsPosterImage}
						width={640} height={640 * 1.5} />}

					{!(sanityPosterUrl || cmsPosterImage) && streamingPosterImageFallback && <PictureFallbackImage alt={cmsImage?.asset?.altText ?? fallbackAltText}
						imageUrl={streamingPosterImageFallback}
						width={640} height={640 * 1.5} />}
				</>}

				{!hasMobileImage && <>
					{(sanityWideImageUrl || cmsImage) && <PictureFallbackImage alt={cmsImage?.asset?.altText ?? fallbackAltText}
						imageUrl={sanityWideImageUrl}
						cmsImage={cmsImage}
                        hotspot={cmsImage?.asset?.hotspot}
						width={640} height={640 * 0.5} />}

					{!sanityWideImageUrl && (_streamingImages?.length ?? 0) > 0 && <PictureFallbackImage alt={cmsImage?.asset?.altText ?? fallbackAltText}
						imageUrl={_streamingImages![_streamingImages!.length - 1].url}
						width={640} height={640 * 0.5} />}
				</>}
			</>}

			{!isStreamingContext && <>
				{hasDesktopImage && (cmsImage || sanityWideImageUrl) && <>
					<PictureSource media="(min-width: 1600px)" cmsImage={cmsImage} imgUrl={sanityWideImageUrl} width={2000} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
					<PictureSource media="(min-width: 1201px)" cmsImage={cmsImage} imgUrl={sanityWideImageUrl} width={1400} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
					<PictureSource media="(min-width: 641px)" cmsImage={cmsImage} imgUrl={sanityWideImageUrl} width={1200} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
					<PictureSource media={theme.mq.desktop} cmsImage={cmsImage} imgUrl={sanityWideImageUrl} width={640} aspect={2} hotspot={cmsImage?.asset?.hotspot}/>
				</>}
				{hasMobileImage &&
					(sanityPosterUrl || cmsPosterImage) && <PictureFallbackImage alt={cmsImage?.asset?.altText ?? fallbackAltText}
						imageUrl={sanityPosterUrl}
						cmsImage={cmsPosterImage}
						width={640} height={640 * 1.5} />}

				{/** Use wide image as fallback if poster is not available */}
				{!hasMobileImage &&
					(sanityWideImageUrl || cmsImage) && <PictureFallbackImage alt={cmsImage?.asset?.altText ?? fallbackAltText}
						imageUrl={sanityWideImageUrl}
						cmsImage={cmsImage}
                        hotspot={cmsImage?.asset?.hotspot}
						width={640} height={640 * 0.5} />}
			</>}

		</Picture>
		}
	</STopImageContainer>;
}
//#endregion

//#region [Styles]
type STopImageContainerProps = {
	$isExpanded: boolean;
	$isShowingTrailer: boolean;
	$isUsingWideFallback: boolean;
}

const STopImageContainer = styled.div<STopImageContainerProps>`
	width: 100%;
	z-index: -1;
	position: relative;
	${props => !props.$isUsingWideFallback ? css`
		margin-bottom: -90vw;
	`: css`
		margin-bottom: -15px;
	`}

	transition: margin-bottom 1s, height 0.5s;
	min-height: 51vw;

	@media ${theme.mq.desktop} {
		//height: 20vw;
		min-height: 20vw;
		margin-bottom: -20vw;
	}

	${props => props.$isExpanded ? css`
			margin-bottom: 0 !important;
	`: ""}


	picture {
		opacity: 1;
		transition: opacity 0.5s;
	}

	${props => props.$isShowingTrailer ? css`
		margin-bottom: 0 !important;
		height: min(calc(100vw / (16 / 9)), calc(${theme.maxContentWidth}px / (16 / 9)));

		picture {
			opacity: 0;
		}
	` : ""}


	@media ${theme.mq.desktop} {
		picture img:first-child:last-child {
			display: none;
		}
	}

`;


const SMissingMobileImage = styled(MissingImage)`
	@media ${theme.mq.desktop} {
		display: none;
	}
`;

//#endregion