import CheckmarkSvg from "src/components/svg/CheckMarkSvg";
import StarSvg from "src/components/svg/StarSvg";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from "src/lib/styles/css";

export const SRatingContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10%;
	margin-bottom: 10vh;

	@media ${theme.mq.desktop} {
		gap: 10px;
		margin-bottom: 20px;
	}
`;

export const SEditorContainer = styled.div`
	border: 2px solid var(--buttonactivecolor);
	width: 100%;
	border-radius: var(--panel-borderradius);
	margin-bottom: 5px;
	padding: 10px;
	min-height: 200px;
	overflow: hidden;

	.Editor {
		//height: 100%;
		div[data-block="true"]:first-child > div {
			margin-top: 0;
		}
		div[data-block="true"]:last-child > div {
			margin-bottom: 0;
		}
	}
`;

export const SRatingStar = styled(StarSvg)`
	width: 12vw;
	height: auto;

	@media ${theme.mq.desktop} {
		width: 50px;
	}
`;

export const SRatingButton = styled.button`
	background: none;
	padding: 0;
	border: none;
	font-size: 2.5rem;
	color: ${theme.textColor};

	opacity: 0.15;
	transition: opacity 0.5s;

	&:enabled {
		opacity: 1;
	}
`;

export const SReviewActions = styled.div`
	width: 100%;
	margin-bottom: 3vh;
	display: flex;
	justify-content: space-between;
`;

export const SSaveContainer = styled.span`
	display: flex;
	align-items: center;
`;

type SSavedCheckMarkProps = {
	$isOk?: boolean;
}

export const SSavedCheckMark = styled(CheckmarkSvg) <SSavedCheckMarkProps>`
	opacity: 0;
	width: 1em;
	margin-right: 0.5em;

	path {
		fill: ${theme.palette.grønn};
	}

	@keyframes fadeinOut {
		from {
			opacity: 0;
		}

		25% {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	${props => props.$isOk ? css`
		animation-duration: 3s;
		animation-name: fadeinOut;
		animation-iteration-count: 1;
	`: null}
`;

export const SErrorMessage = styled.div`
	border-radius: var(--panel-borderradius);
	border: 2px solid ${theme.palette.oransje};
	padding: 5px 10px;
	width: 100%;
	margin-top: -2vh;
`;
