import { toPlainText } from "@portabletext/react";
import { stripEmptyParagraphs } from "src/components/richtext/Richtext";
import KinoklubbSelectionSvg from "src/components/svg/KinoklubbSelection";
import theme from "src/components/themes/DefaultTheme";
import { getImageLoader } from "src/lib/client/kinoklubb/kinoklubbImageUrls";
import styled from 'src/lib/styles/css';
import { KinoklubbOmtale } from "src/lib/types/Kinoklubb";
import NextImage from "next/image";
import { useMemo } from "react";

//#region [Props]
type KKPromoProps = {
	kkMovie: KinoklubbOmtale;
};
//#endregion

const PORTRAIT_SIZE = 48;

//#region [Component]
export default function KKPromo({ kkMovie }: KKPromoProps) {
	const text = useMemo(() => {
		let _text = (kkMovie?.review?.ingress?.length ?? 0) > 0 ? kkMovie!.review!.ingress : kkMovie?.review?.mainText;
		if (!_text) {
			return null;
		}
		_text = stripEmptyParagraphs(_text!);
		let plainText = toPlainText([_text[0]]);
		// now we need to check if the text is too long
		const indexOfFakeParagraph = plainText.indexOf("\n\n");
		if (indexOfFakeParagraph !== -1) {
			plainText = plainText.substring(0, indexOfFakeParagraph);
		}
		return plainText;
	}, [kkMovie]);

	const imgLoader = useMemo(() => {
		return getImageLoader(1, kkMovie?.review?.author?.portrait?.image?.hotspot ?? kkMovie?.reviewer?.portrait?.image?.hotspot);
	}, [kkMovie?.review?.author?.portrait?.image?.hotspot, kkMovie?.reviewer?.portrait?.image?.hotspot]);

	if (text === null || !kkMovie?.review) {
		return null;
	}

	const author = kkMovie?.review?.author ?? kkMovie?.reviewer;

	return <SContainer>
		<SKinoklubbSelectionSvg />
		<SWhy>Hvorfor valgte Kinoklubben denne filmen?</SWhy>
		<SText>{text} <SReadMore target="_blank" href={`https://www.kinoklubb.no/artikkel/${kkMovie.review.slug.current}?utm_source=Filmweb&utm_medium=KK_utvalgte_boks`}>Les mer</SReadMore></SText>
		<SAuthor>
			{author?.portrait?.image?.asset?._ref && <SNextImg loading="lazy" width={PORTRAIT_SIZE} height={PORTRAIT_SIZE} src={author!.portrait.image.asset?._ref!} alt={author!.name} quality={100} loader={imgLoader} />}
			{author && <SAuthorName><span>{author.name}</span> <span>{author.workTitle}</span></SAuthorName>}
		</SAuthor>
	</SContainer>;
}
//#endregion

//#region [Styles]
const SContainer = styled.section`
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 8px;

	margin-bottom: 1rem;
	text-align: center;
	color: var(--textcolor);

	@media ${theme.mq.desktop} {
		margin-bottom: 0;
		grid-area: kkpromo;
		background: ${theme.palette.lilla4};

	}
`;

const SKinoklubbSelectionSvg = styled(KinoklubbSelectionSvg)`
	max-width: 150px;
	display: block;
	margin: 0 auto 1em auto;
`;

const SWhy = styled.p`
	font-weight: bold;
`;

const SText = styled.p`
	font-size: 1rem;
	font-weight: 200;
`;

const SReadMore = styled.a`
	color: var(--textcolor);
	text-decoration: underline;
`;

const SAuthor = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	gap: 1em;
	color: var(--kinoklubbcolor);
`;

const SAuthorName = styled.div`
	@media ${theme.mq.mobile} {
		span:first-child::after {
			content: ", ";
		}
	}
	@media ${theme.mq.desktop} {
		text-align: left;
		span {
			display: block;
		}
	}
`;

const SNextImg = styled(NextImage)`
	width: ${PORTRAIT_SIZE + 4}px;
	height: ${PORTRAIT_SIZE + 4}px;
	border-radius: 50%;
	border: 2px solid var(--kinoklubbcolor);
	flex: 0 0 auto;

`;

//#endregion