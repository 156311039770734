import Button from "src/components/button/Button";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import styled from 'src/lib/styles/css';

//#region [Props]
type CinemaSelectorButtonProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function CinemaSelectorButton({ className }: CinemaSelectorButtonProps) {
	const cinemaContext = useSelectedCinemaContext();
	if ((cinemaContext?.availableCinemas?.length ?? 0) < 2) {
		return null;
	}
	return <Button className={className}
		onClick={() => cinemaContext.setShowSelectCinemaDialog(true)}
		text={cinemaContext.selectedCinemas?.length ? cinemaContext.selectedCinemas[0] : "Alle kinoer"}
	/>;
}
//#endregion

//#region [Styles]

//#endregion