import { IMovieActivity } from "src/lib/filmwebid/IMovieActivity";
import useUserApi from "src/lib/filmwebid/useUserApi";
import { useCallback, useEffect, useRef, useState } from "react";



export default function useMovieActivity(edi: string | undefined | null, streamingId: string | number | undefined | null)
	: [activity: IMovieActivity | null, updater: () => void, isLoading: boolean] {

	const [movieActivity, setMovieActivity] = useState<IMovieActivity | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const fetcher = useUserApi();
	const abortController = useRef<AbortController | null>(null);
	const [_updateTrigger, setUpdateTrigger] = useState(0); // used to trigger a re-load of data

	const updater = useCallback(() => {
		setUpdateTrigger(prevTriggerVal => prevTriggerVal + 1);
	}, []);

	useEffect(() => {
		if (edi || streamingId) {
			if (abortController.current) {
				// stop any previous loads
				abortController.current.abort();
			}
			abortController.current = new AbortController();
			const _f = async () => {
				try {
					setIsLoading(true);
					var resp = await fetcher("/api/Movie/MovieActivity",
						{
							method: "GET",
							signal: abortController.current!.signal,
							allowAnon: true,
							params: { edi: edi ?? null, streamingId: streamingId ?? null }
						});
					setMovieActivity({ edi, streamingId, ...resp });
					abortController.current = null;
					setIsLoading(false);
				} catch (ex: any) {
					if (ex.name !== "AbortError") {
						console.error(ex);
					}
				}
			};
			_f();
			return () => {
				if (abortController.current) {
					abortController.current.abort();
				}
			};

		} else {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edi, streamingId, _updateTrigger]);

	return [movieActivity, updater, isLoading];
}