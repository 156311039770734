import { Genre, Premiere } from "@filmweb/sanity-types";
import Selectors from "src/components/film/program/Selectors";
import Shows from "src/components/film/program/Shows";
import Kinoklubb from "src/components/kinoklubb/Kinoklubb";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import NoLocation from "src/components/program/NoLocation";
import KinoklubbSelectionSvg from "src/components/svg/KinoklubbSelection";
import theme from "src/components/themes/DefaultTheme";
import { format } from "date-fns";
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import { ShowType } from "src/lib/movieinfo/movieinfotypes";
import styled from 'src/lib/styles/css';
import React, { useEffect, useMemo, useState } from "react";

const SHOWDATES_QUERY = gql`
query ($location: String, $movieId: String) {
	showQuery {
		getShowDates (location: $location, movieId: $movieId) {
			__typename
			date
		}
	}
}`;

const SHOWSFORDATE_QUERY = gql`query ($location: String, $movieId: String, $date: String) {
	showQuery{
		getShows(location: $location, movieId: $movieId, date: $date, removePastShows: true) {
			__typename
			movieMainVersionId
			movieVersionId
			movieTitle
			showStart
			ticketSaleUrl
			screenName
			theaterName
			showType
			firmName
			isKinoklubb
			versionTags {
				tag
			}
		}
	}
}`;

//#region [Props]
type ProgramDisplayProps = {
	edi?: string;
	movieTitle?: string;
	premiere?: Premiere;
	children?: React.ReactNode;
	genres?: Genre[];
	isKinoklubbMovie?: boolean;
	kinoklubbClassName?: string;
	hideCinemaSelector?: boolean;
	className?: string;
};
//#endregion

//#region [Component]
export default function ProgramDisplay({ edi, movieTitle, premiere, genres, isKinoklubbMovie = false, children, kinoklubbClassName, hideCinemaSelector = false, className }: ProgramDisplayProps) {
	const locationContext = useLocationContext();
	const programDatesContext = useProgramDatesContext();

	const { fiData } = useFilminfo(SHOWDATES_QUERY,
		{ location: locationContext.location, movieId: edi },
		{ active: !!edi && !!locationContext.location });

	const { fiLoading: showsLoading, fiLoaded: showsLoaded, fiData: showsData } = useFilminfo(SHOWSFORDATE_QUERY,
		{ location: locationContext.location, movieId: edi, date: format(programDatesContext.selectedDate!, 'yyyy-MM-dd') },
		{ active: !!locationContext.location && !!programDatesContext.selectedDate });

	const hasKinoklubbShows = useMemo(() => {
		return showsData?.showQuery?.getShows?.some(show => show?.isKinoklubb) ?? false;
	}, [showsData?.showQuery?.getShows]);

	useEffect(() => {
		if (fiData?.showQuery?.getShowDates) {
			const listAsDates = fiData.showQuery.getShowDates.map(d => new Date(d!.date));
			programDatesContext.setAvailableDates(listAsDates);
		} else {
			programDatesContext.setAvailableDates(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fiData]);

	if (!edi || !movieTitle) {
		return null;
	}

	return <SProgramWrapper className={className}>
		{hasKinoklubbShows && <>
			<SKinoklubbSelectionSvg />
			<SKinoklubb className={kinoklubbClassName} />
		</>}
		{isKinoklubbMovie && !hasKinoklubbShows && showsLoaded && (showsData?.showQuery?.getShows?.length ?? 0) === 0 && <SKinoklubbSelectionSvg />}
		<SSelectors hideCinemaSelector={hideCinemaSelector} />
		{programDatesContext.selectedDate && <SShowWrapper>
			{showsLoading && !showsLoaded && <LoadingIndicator />}
			{!locationContext?.location && <NoLocation />}
			{showsLoaded && <Shows edi={edi}
				movieTitle={movieTitle}
				premiere={premiere}
				genres={genres}
				isKinoklubbMovie={isKinoklubbMovie} shows={showsData?.showQuery?.getShows as ShowType[]} />}
		</SShowWrapper>}
		{children}
	</SProgramWrapper>;
}
//#endregion

//#region [Styles]
const SProgramWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		margin-top: 30px;
	}
`;

const SShowWrapper = styled.div`
	width: 100%;
	order: 3;
`;

const SKinoklubb = styled(Kinoklubb)`
	width: 100%;
	order: 1;
	margin: -10px 0px 20px 0px;
	align-self: flex-end;
	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		order: 2;
		width: unset;
		margin: 0;
	}
`;

const SSelectors = styled(Selectors)`
	width: 100%;
	order: 2;
	align-self: flex-end;

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		order: 1;
		width: unset;
	}
`;

const SKinoklubbSelectionSvg = styled(KinoklubbSelectionSvg)`
	max-width: 150px;
	display: block;
	margin: 0 auto 1em auto;

	@media ${theme.mq.desktop} {
		display: none;
	}
`;

//#endregion