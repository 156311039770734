import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from "src/lib/styles/css";

//#region [Styles]
const SSpeechBubbleSvg = styled(SDefaultSvg)`
	fill: none;

	path {
		fill: ${theme.palette.turkis2};
	}
`;

//#endregion

//#region [Props]
type WatchlistSvgProps = {
	selected?: boolean;
} & StandardSvgProps;

//#endregion

//#region [Component]
export default function SpeechBubbleSvg({ className, selected = false }: WatchlistSvgProps) {
	return (
		<SSpeechBubbleSvg className={className} width="24.88" height="21.874" viewBox="0 0 24.88 21.874">
			<SFilledPath $isFilled={!selected}
				d="m 8.5670009,18.973 1.7480001,-2.929 h 8.564 a 4,4 0 0 0 4,-4 V 6 a 4,4 0 0 0 -4,-4 H 6.0000009 a 4,4 0 0 0 -4,4 v 6.044 a 3.991,3.991 0 0 0 3.814,4 l 1.1,0.05 1.657,2.883 m -0.015,2.9 a 1.071,1.071 0 0 1 -0.937,-0.543 l -1.893,-3.293 A 6,6 0 0 1 8.8539321e-7,12.044 V 6 A 6,6 0 0 1 6.0000009,0 H 18.879001 a 6,6 0 0 1 6,6 v 6.044 a 6,6 0 0 1 -6,6 h -7.429 l -1.9710001,3.3 a 1.07,1.07 0 0 1 -0.927,0.53 z" />
			<SFilledPath $isFilled={selected}
				d="m 8.5670009,18.973 1.7480001,-2.929 h 8.564 c 2.209139,0 4,-1.790861 4,-4 V 6 c 0,-2.209139 -1.790861,-4 -4,-4 H 6.0000009 c -2.209139,0 -4,1.790861 -4,4 v 6.044 c -0.0069,2.140337 1.675757,3.905087 3.814,4 l 1.1,0.05 1.657,2.883" />
		</SSpeechBubbleSvg>
	);
}
//#endregion