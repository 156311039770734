import styled from 'src/lib/styles/css';
import type { IWatchlistable } from 'src/components/filmwebid/withWatchlistState';
import WatchlistSvg from 'src/components/svg/WatchlistSvg';
import theme from 'src/components/themes/DefaultTheme';

//#region [Styles]
const SButton = styled.button`
	width: 42px;
	margin-right: 44px;
	flex: 0 0 auto;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
`;

const SWatchlistIcon = styled(WatchlistSvg)`
	display: block;
	width: 100%;
	height: auto;

	path {
		fill: var(--buttontextcolor);
	}
`;
//#endregion

//#region [Props]
type WatchlistButtonProps = {
	className?: string;
} & IWatchlistable;
//#endregion

//#region [Component]
export default function WatchlistButton({ isWatchlisted, onWatchlistClick, className }: WatchlistButtonProps) {
	return <SButton onClick={onWatchlistClick} className={className}>
		<SWatchlistIcon selected={isWatchlisted} />
	</SButton>;
}
//#endregion