import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from "src/lib/styles/css";

//#region [Styles]
const SStarSvg = styled(SDefaultSvg)`
	fill: none;

	path {
		fill: ${theme.palette.turkis2};
	}
`;

//#endregion

//#region [Props]
type WatchlistSvgProps = {
	selected?: boolean;
	fractionSelected?: number;
} & StandardSvgProps;

//#endregion

//#region [Component]
const BASE_WIDTHV2 = 28.285;

export default function StarSvg({ className,  selected = false, fractionSelected = 0 }: WatchlistSvgProps) {
	const id = Math.floor(Math.random() * 100);
	return (
		<SStarSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.285 28.211" width={BASE_WIDTHV2} height="28.211">
			{!selected && fractionSelected > 0.0 && <defs><clipPath id={"fillClip" + id}>
				<SFilledPath as="rect" x="0" y="0" width={BASE_WIDTHV2 * fractionSelected} height="28.211" $isFilled  />
			</clipPath></defs>}
			<SFilledPath $isFilled
				d="m 14.143,2.0001175 c -0.164363,0 -0.328725,0.096376 -0.38433,0.2891312 L 11.30322,10.80095 c -0.05025,0.174179 -0.211352,0.292782 -0.3926,0.289049 l -8.5011806,-0.17571 c -0.3906212,-0.0081 -0.5599823,0.49152 -0.2446899,0.722561 l 7.0109997,5.13766 c 0.1398201,0.10246 0.197731,0.283309 0.1434402,0.447928 l -2.7907295,8.461441 c -0.1244888,0.37743 0.3180237,0.685761 0.6289806,0.43825 l 6.7364495,-5.362 c 0.145799,-0.116049 0.352421,-0.116049 0.49822,0 l 6.736449,5.362 c 0.310961,0.247509 0.753472,-0.06082 0.628981,-0.43825 l -2.790729,-8.461441 c -0.0543,-0.164619 0.0036,-0.345468 0.14344,-0.447928 l 7.011,-5.13766 c 0.31529,-0.23104 0.14611,-0.730503 -0.244702,-0.722561 L 17.37538,11.08999 c -0.181269,0.0037 -0.342351,-0.114861 -0.3926,-0.28904 L 14.52733,2.2892487 C 14.471725,2.0964939 14.307363,2.0001175 14.143,2.0001175 m 0,-1.99999807 c 1.07992,0 2.00662,0.69713974 2.30596,1.73475077 l 2.114595,7.3301372 7.271666,-0.1502876 c 0.01885,-3.7e-4 0.03483,-5.302e-4 0.05066,-5.302e-4 1.0401,0 1.956369,0.6646595 2.280029,1.6539194 0.323582,0.989031 -0.02304,2.06683 -0.862518,2.681981 l -6.072981,4.45028 2.426491,7.357069 c 0.240719,0.72982 0.11697,1.53542 -0.331042,2.154951 -0.452259,0.625389 -1.181348,0.99876 -1.950319,0.99876 -0.537741,0 -1.067779,-0.186151 -1.49246,-0.524171 l -5.74008,-4.568913 -5.7400218,4.568873 C 7.9783004,28.02498 7.4482406,28.21115 6.9104599,28.21115 6.1414703,28.21115 5.4123691,27.837779 4.9601196,27.212379 4.5121101,26.592859 4.3883709,25.78725 4.6291202,25.057389 L 7.05559,17.70037 0.98258054,13.25007 C 0.14320981,12.634989 -0.2034108,11.557269 0.1200603,10.568319 0.44361913,9.5790894 1.3597797,8.9143498 2.3997997,8.9141896 c 0.01829,0 0.035921,1.907e-4 0.05407,5.893e-4 L 9.722443,9.065017 11.83703,1.7348988 C 12.13632,0.69729922 13.06302,1.1943e-4 14.143,1.1943e-4 Z"
				stroke="none" />
			<SFilledPath $isFilled={selected || fractionSelected > 0}
				d="m 14.143,1.1936e-4 c 1.07992,0 2.00662,0.69713974 2.30596,1.73475074 l 2.114595,7.3301373 7.271666,-0.1502876 c 0.01885,-3.7e-4 0.03483,-5.302e-4 0.05066,-5.302e-4 1.0401,0 1.956369,0.6646595 2.280029,1.6539194 0.323582,0.989031 -0.02304,2.06683 -0.862518,2.681981 l -6.072981,4.45028 2.426491,7.357069 c 0.240719,0.72982 0.11697,1.53542 -0.331042,2.154951 -0.452259,0.625389 -1.181348,0.99876 -1.950319,0.99876 -0.537741,0 -1.067779,-0.186151 -1.49246,-0.524171 L 14.143001,23.118066 8.40298,27.686939 C 7.978301,28.02498 7.448241,28.21115 6.91046,28.21115 6.141471,28.21115 5.412369,27.837779 4.96012,27.212379 4.51211,26.592859 4.388371,25.78725 4.629121,25.057389 L 7.05559,17.70037 0.982581,13.25007 C 0.14321,12.634989 -0.20341,11.557269 0.120061,10.568319 0.443619,9.5790894 1.35978,8.9143498 2.3998,8.9141896 c 0.01829,0 0.03592,1.907e-4 0.05407,5.893e-4 L 9.722443,9.065017 11.83703,1.7348988 C 12.13632,0.69729915 13.06302,1.1936e-4 14.143,1.1936e-4 Z"
				clipPath={fractionSelected > 0 ? `url(#fillClip${id})` : undefined} />
		</SStarSvg>
	);
}
//#endregion