import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import styled from 'src/lib/styles/css';
import React from "react";

//#region [Props]
type PeopleProps = {
	label: string;
	className?: string;
	skipLink?: boolean;
	persons?: string;
};
//#endregion

//#region [Component]
export default function People({ label, className, skipLink, persons }: PeopleProps) {
	let names: string[] | undefined = persons?.replace(/ og /g, ',').split(",").map(n => n.trim()).filter( n => n!== "");

	if ((names?.length ?? 0) === 0) {
		return null;
	}

	return <div><span>{label}: </span>
		{names?.map((name, index) => <React.Fragment key={`${name}_${index}`}>{index > 0 ? ", " : ""}
			{skipLink ? name : <SActorName to={`/navn/${name.replace(/ /g, "_")}`}>{name}</SActorName>}
		</React.Fragment>)}
	</div>;
}
//#endregion

//#region [Styles]
const SActorName = styled(FilmwebLink)`
	color: var(--TEXTCOLOR-OVERRIDE, var(--buttontextcolor));
`;
//#endregion