import { useMovieActivityContext } from "src/components/film/filmwebid/MovieActivityContext";
import RatingPanel from "src/components/filmwebid/RatingPanel";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import StarSvg from "src/components/svg/StarSvg";
import theme from "src/components/themes/DefaultTheme";
import getFraction from "src/lib/filmwebid/getFraction";
import styled from 'src/lib/styles/css';
import { useState } from "react";

//#region [Props]
type UserRatingsProps = {
	className?: string;
	edi?: string;
	streamingId?: number;
};
//#endregion

//#region [Component]
export default function UserRatings({ className, edi, streamingId }: UserRatingsProps) {
	const movieActivityContext = useMovieActivityContext();

	const [showRatingPanel, setShowRatingPanel] = useState(false);

	const isLoading = movieActivityContext.isLoading;

	const onRatingPanelClose = (doUpdate: boolean) => {
		setShowRatingPanel(false);
		if (doUpdate) {
			movieActivityContext.activityUpdater();
		}
	}

	return <SRatingsDisplay className={className}>
		{isLoading && <LoadingIndicator />}
		<SHeading>{(isLoading || !movieActivityContext.activity?.movieUserInteraction?.rating) ? "Gi din vurdering:" : "Gjennomsnitt av vurderinger:"}</SHeading>
		<SStarContainer>
			<SAverageMarkerStar fractionSelected={movieActivityContext.isLoading ? 0 : getFraction(movieActivityContext.activity?.movieStats?.ratingAverage, 1)} />
			<SAverageMarkerStar fractionSelected={movieActivityContext.isLoading ? 0 : getFraction(movieActivityContext.activity?.movieStats?.ratingAverage, 2)} />
			<SAverageMarkerStar fractionSelected={movieActivityContext.isLoading ? 0 : getFraction(movieActivityContext.activity?.movieStats?.ratingAverage, 3)} />
			<SAverageMarkerStar fractionSelected={movieActivityContext.isLoading ? 0 : getFraction(movieActivityContext.activity?.movieStats?.ratingAverage, 4)} />
			<SAverageMarkerStar fractionSelected={movieActivityContext.isLoading ? 0 : getFraction(movieActivityContext.activity?.movieStats?.ratingAverage, 5)} />
		</SStarContainer>
		{!isLoading && <>
			{(movieActivityContext.activity?.movieStats?.ratingCount ?? 0) > 0 && <SNumRated>Så langt har {movieActivityContext.activity?.movieStats?.ratingCount} gitt vurdering</SNumRated>}
			{(movieActivityContext.activity?.movieUserInteraction?.rating ?? 0) > 0 && <>
				<SYourRatingHeading>Din vurdering:</SYourRatingHeading>
				<SStarContainer>
					<SUserRatingMarkerStar selected={movieActivityContext.activity?.movieUserInteraction?.rating! >= 1} />
					<SUserRatingMarkerStar selected={movieActivityContext.activity?.movieUserInteraction?.rating! >= 2} />
					<SUserRatingMarkerStar selected={movieActivityContext.activity?.movieUserInteraction?.rating! >= 3} />
					<SUserRatingMarkerStar selected={movieActivityContext.activity?.movieUserInteraction?.rating! >= 4} />
					<SUserRatingMarkerStar selected={movieActivityContext.activity?.movieUserInteraction?.rating! === 5} />
				</SStarContainer>
			</>}
		</>}

		{showRatingPanel && <RatingPanel
			edi={edi}
			streamingId={streamingId}
			onClose={onRatingPanelClose}
			movieStats={movieActivityContext.activity?.movieStats}
			hasUserReview={!!movieActivityContext.activity?.movieUserInteraction?.review}
			userRating={movieActivityContext.activity?.movieUserInteraction?.rating}
		/>}

	</SRatingsDisplay>;
}
//#endregion

//#region [Styles]
const SRatingsDisplay = styled.div`
	color: var(--textcolor);
	cursor: pointer;
	transition: opacity 0.5s;
	position: relative;
`;

const SHeading = styled.h4`
	text-align: center;
	font-size: ${theme.fonts.tittelXS.size};
	flex: 0 0 100%;
	order: 0;

	@media ${theme.mq.desktop} {
		flex: 0 0 auto;
		//font-size: map.get(settings.$tittelS, "size");
		align-self: center;
	}
`;

const SYourRatingHeading = styled(SHeading)`
	margin-top: 20px;

`;

const SStarContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	width: 100%;
	margin: 5px 0;
`;

const SAverageMarkerStar = styled(StarSvg)`
	width: 40px;
	height: auto;
`;

const SUserRatingMarkerStar = styled(StarSvg)`
	width: 30px;
	height: auto;
`;

const SNumRated = styled.div`
	text-align: center;
	font-size: ${theme.fonts.bodytextS.size};
`;
//#endregion