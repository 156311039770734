import { usePrevious } from "@react-hooks-library/core";
import useUserApi from "src/lib/filmwebid/useUserApi";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type UseFavouriteArgs = {
	edi?: string | null;
	streamingId?: number | null;
	onComplete?: () => void;
}


export default function useFavourite({ edi, streamingId, onComplete }: UseFavouriteArgs): [Dispatch<SetStateAction<boolean | null>>, boolean] {
	const fetcher = useUserApi();
	const [isWorking, setIsWorking] = useState(false);

	const [isFav, setIsFav] = useState<boolean | null>(null);
	const prevEdi = usePrevious(edi);
	const prevStreamingId = usePrevious(streamingId);

	useEffect(() => {
		// reset the hook if the edi or streamingId changes
		if (edi !== prevEdi || streamingId !== prevStreamingId) {
			setIsFav(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edi, streamingId]);

	useEffect(() => {

		if (isFav !== null) {
			setIsWorking(true);
			const _do = async () => {
				if (isFav) {
					await fetcher("/api/Favourites", { method: "POST", allowAnon: false, body: { EDI: edi ?? null, streamingId: streamingId ?? null, source: window?.location?.hostname ?? null } })
				} else {
					await fetcher("/api/Favourites", { method: "DELETE", allowAnon: false, params: { EDI: edi ?? null, streamingId: streamingId ?? null } })
				}
				setIsWorking(false);

				onComplete?.();

			};
			_do();

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFav]);


	return [setIsFav, isWorking];
}