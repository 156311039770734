import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
import theme from 'src/components/themes/DefaultTheme';
import styled from "src/lib/styles/css";

//#region [Styles]
const SHeartSvg = styled(SDefaultSvg)`
	fill: none;

	path {
		fill: var(--ICONCOLOR_OVERRIDE, ${theme.palette.turkis2});
	}
`;

//#endregion

//#region [Props]
type HeartSvgProps = {
	selected?: boolean;
} & StandardSvgProps;

//#endregion

//#region [Component]
export default function HeartSvg({ className, selected = false }: HeartSvgProps) {
	return (
		<SHeartSvg className={className} xmlns="http://www.w3.org/2000/svg" width="24.457563" height="20.545681" viewBox="0 0 24.457564 20.54568">
			<SFilledPath $isFilled={!selected}
				d="m 12.154886,20.545675 c -1.146515,0.0019 -2.2491117,-0.440798 -3.0759999,-1.235 l -0.016,-0.015 -6.988,-6.99 C -0.69466457,9.500421 -0.69118859,4.9888952 2.082675,2.1879093 4.8565386,-0.61307663 9.359931,-0.66044963 12.192,2.0816748 15.157087,-0.59678783 19.579058,-0.63610806 22.375,2.188 c 2.795941,2.8241081 2.773996,7.3247316 -0.05012,10.120675 l -7.092,7 c -0.82707,0.795413 -1.930514,1.23887 -3.078,1.237 z m -1.686,-2.673 c 0.945633,0.898416 2.429367,0.898416 3.375,0 l 7.074,-6.985 c 4.478548,-4.9185175 -2.516918,-11.83917102 -7.387,-7.3080002 l -0.006,0.005 -1.333,1.3 -1.321,-1.307 C 6.007799,-1.7985224 -1.8383674,5.9616095 3.4838861,10.883675 v 0 z" />
			<SFilledPath $isFilled={selected}
				d="m 10.468886,17.872675 c 0.945633,0.898416 2.429367,0.898416 3.375,0 l 7.074,-6.984999 c 4.478554,-4.9185184 -2.516918,-11.8391781 -7.387,-7.3080013 l -0.006,0.005 -1.333,1.3000001 -1.321,-1.3070001 C 6.007798,-1.7985146 -1.83836,5.9616096 3.483886,10.883676 v 0 z" />
		</SHeartSvg>
	);
}
//#endregion