import { Premiere } from "@filmweb/sanity-types";
import styled from 'src/lib/styles/css';
import { parse, isSameYear, format, isToday, isAfter, addMonths } from "date-fns";
import locale from "date-fns/locale/nb";
import theme from "src/components/themes/DefaultTheme";
import WatchlistButton from "src/components/filmwebid/WatchlistButton";
import withWatchlistState from "src/components/filmwebid/withWatchlistState";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import Button from "src/components/button/Button";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import CalendarSvg from "src/components/svg/CalendarSvg";

//#region [Props]
type NoShowsProps = {
	edi: string;
	movieTitle: string;
	premiere?: Premiere;

};
//#endregion



//#region [Component]
export default function NoShows({ edi, movieTitle, premiere }: NoShowsProps) {
	const datesContext = useProgramDatesContext();
	const cinemaContext = useSelectedCinemaContext();

	let desktopTextToShow: string | React.ReactNode = "Denne filmen har ingen flere visninger. Legg denne filmen i huskelisten og få påminnelse når den kommer på en strømmetjeneste.";
	let mobileTextToShow: string | React.ReactNode | undefined;
	let premiereParsed: Date | undefined;


	if (premiere?.premiereDate) {
		premiereParsed = parse(premiere!.premiereDate, "yyyy-MM-dd", new Date());
	} else if (premiere?.expectedPremiereDate) {
		premiereParsed = parse(premiere!.expectedPremiereDate, "yyyy-MM-dd", new Date());
	}


	if (premiere?.expectedPremiereDate) {
		const isThisYear = isSameYear(premiereParsed!, new Date());
		desktopTextToShow = `"${movieTitle}" har antatt kinopremiere ${format(premiereParsed!, isThisYear ? "MMMM" : "MMMM yyyy", { locale })}. Vil du varsles når billettsalget starter, så kan du legge filmen i huskelisten.`;
		mobileTextToShow = (<>
			<strong>Antatt kinopremiere {format(premiereParsed!, isThisYear ? "MMMM" : "MMMM yyyy", { locale })}.</strong>
			Klikk her for varsling om billettsalg.
		</>);
	} else if (datesContext.availableDates?.[0] && !isToday(datesContext.availableDates![0])) {
		desktopTextToShow = (<>Neste visning for denne filmen er <SInlineDateSelector
			text={format(new Date(datesContext.availableDates![0]), "iiii d. MMMM", { locale })}
			onClick={() => { datesContext.setSelectedDate(datesContext.availableDates![0]); cinemaContext.setSelectedCinemas(null); }}
			icon={<SCalendarSvg />}
			inverse />
		</>);
	} else if (premiereParsed && isAfter(premiereParsed, addMonths(new Date(), -2))) {
		desktopTextToShow = `Det er ingen forestillinger av "${movieTitle}" i salg på din kino akkurat nå. Vil du varsles hvis filmen settes opp, så kan du legge filmen i huskelisten.`;
		mobileTextToShow = (<>
			<strong>Ingen forestillinger i salg på din kino akkurat nå.</strong>
			Klikk her for varsling om billettsalg.
		</>);
	} else if (premiere?.premiereDate) {
		const isThisYear = isSameYear(premiereParsed!, new Date());
		desktopTextToShow = `"${movieTitle}" har kinopremiere ${format(premiereParsed!, isThisYear ? "d. MMMM" : "d. MMMM yyyy", { locale })}. Vil du varsles når billettsalget starter, så kan du legge filmen i huskelisten.`;
		mobileTextToShow = (<>
			<strong>Kinopremiere {format(premiereParsed!, isThisYear ? "d. MMMM" : "d. MMMM yyyy", { locale })}.</strong>
			Klikk her for varsling om billettsalg.
		</>);
	}


	return <SNoShows>
		<WLWatchListButton edi={edi} />
		<SMobileText>
			{mobileTextToShow ? mobileTextToShow : desktopTextToShow}
		</SMobileText>
		<SDesktopText>
			{desktopTextToShow}
		</SDesktopText>
	</SNoShows>;
}
//#endregion

//#region [Styles]
const SNoShows = styled.div`
	background-color: var(--buttoncolor);
	color: var(--buttontextcolor);
	padding: 5px 12px;
	margin: 12px 0;
	border-radius: 8px;
	text-align: center;
	width: 100%;
	display: flex;
	flex-direction: row;

	@media ${theme.mq.desktop} {
		display: block;
		margin: 15px 0px;
		min-height: 321px;
	}
`;

const SWatchlistIcon = styled(WatchlistButton)`
	max-width: 38px;
	margin: 0 10px 0 0;
	flex: 0 0 38px;

	fill: var(--buttontextcolor);
	@media ${theme.mq.desktop} {
		padding-top: 80px !important; // SAN-347
		margin: 0 auto;
	}
`;

const WLWatchListButton = withWatchlistState(SWatchlistIcon);

const SDesktopText = styled.div`
	display: none;
	@media ${theme.mq.desktop} {
		font-size: 16px;
		padding: 29px 34px 0;
		display: block;
	}

`;

const SMobileText = styled.div`
	font-size: 16px;
	padding: 10px 0;
	text-align: left;
	strong {
		display: block;
	}

	@media ${theme.mq.desktop} {
		display: none;
	}
`;

const SInlineDateSelector = styled(Button)`
	margin-left: 1ch;
	vertical-align: middle;
`;

const SCalendarSvg = styled(CalendarSvg)`
	path {
		fill: var(--buttoncolor);
	}
`;
//#endregion