"use client"
import { type ICustomImage } from "@filmweb/sanity-types";
import { useMediaQuery } from "@react-hooks-library/core";
import CmsImage from 'src/components/images/CmsImage';
import { CmsImageForUrl } from "src/components/images/CmsImageForUrl";
import Image from 'src/components/images/Image';
import theme from 'src/components/themes/DefaultTheme';
import { ImageVersionType } from 'src/lib/movieinfo/movieinfotypes';
import styled from 'src/lib/styles/css';

//#region [Props]
type MoviePosterProps = {
	cmsPoster?: ICustomImage;
	filminfoPosters?: ImageVersionType[] | null;
	filmTitle: string;
	sanityPosterUrl?: string;
	isStreamingContext: boolean;
};
//#endregion

//#region [Component]
export default function MoviePoster({ cmsPoster, filminfoPosters, filmTitle, isStreamingContext, sanityPosterUrl }: MoviePosterProps) {
	const isMobile = useMediaQuery(theme.mq.mobile)
	if( isMobile ) {
		return null;
	}

	return <SPosterContainer>
		{isStreamingContext && sanityPosterUrl && <SSanityPoster imageUrl={sanityPosterUrl} alt={`Plakat for '${filmTitle}'`} unoptimized width={333}/>}
		{!(isStreamingContext && sanityPosterUrl) && cmsPoster && <SCmsPoster alt={`Plakat for '${filmTitle}'`} image={cmsPoster} width={333} unoptimized/>}
		{!(isStreamingContext && sanityPosterUrl) && !cmsPoster && (filminfoPosters?.length ?? 0) > 0 && <SFilminfoPoster image={filminfoPosters!} alt={`Plakat for '${filmTitle}'`} sizes="333px"/>}
	</SPosterContainer>;
}
//#endregion

//#region [Styles]
const SPosterContainer = styled.div`
	display: none;
	@media ${theme.mq.desktop} {
		grid-area: poster;
		display: block;
	}
`;

const SCmsPoster = styled(CmsImage)`
	border-radius: ${theme.stdBorderRadius};
`;

const SSanityPoster = styled(CmsImageForUrl)`
	border-radius: ${theme.stdBorderRadius};
`;

const SFilminfoPoster = styled(Image)`
	border-radius: ${theme.stdBorderRadius};
`;
//#endregion