"use client"
import TrailerPlayer from 'src/components/film/TrailerPlayer';
import { useTrailerContext } from 'src/components/film/TrailerWrapper';
import CloseSvg from 'src/components/svg/CloseSvg';
import PlaySvg from 'src/components/svg/PlaySvg';
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';

//#region [Props]
type TrailerContentProps = {
	trailerId?: number | string;
	streamingTrailer?: string;
	className?: string;
	showText?: boolean;
};
//#endregion

//#region [Component]
export default function TrailerContent({ trailerId, streamingTrailer, className, showText = false }: TrailerContentProps) {
	const trailerContext = useTrailerContext();

	if (!trailerId && !streamingTrailer) return null;

	return <>
		<STrailerButton className={className}>
			<SButton onClick={() => trailerContext.setIsPlaying(true)}>
				<SPlaySvg />
				{showText && <SIconText>Trailer</SIconText>}
			</SButton>
		</STrailerButton>
		{trailerContext.isPlaying && <SPlayerContainer>
			<SVideoCloseButton onClick={() => trailerContext.setIsPlaying(false)}>
				<SCloseSvg />
			</SVideoCloseButton>
			<TrailerPlayer trailerId={trailerId} streamingTrailer={streamingTrailer} />
		</SPlayerContainer>}
	</>;
}
//#endregion

//#region [Styles]
const STrailerButton = styled.div`
	position: absolute;
	top: -35vw;
	left: 50vw;
	transform: translateX(-50%);
	line-height: 40px;

	@media ${theme.mq.desktop} {
		position: static;
		transform: none;
		order: 1;
		line-height: 23px;
		align-self: flex-start;
		margin-top: 25px;
	}
`;

const SButton = styled.button`
	background: transparent;
	border: none;
	padding: 0 10px;

`;

const SPlaySvg = styled(PlaySvg)`
	width: 100%;
	max-height: 40px;
	max-width: 40px;
`;

const SIconText = styled.span`
	display: block;
	font-size: 14px;
	line-height: 22px;
	font-weight: normal;
	width: 100%;
	text-align: center;
	color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));

	@media ${theme.mq.desktop} {
		line-height: 1;
	}
`;

const SPlayerContainer = styled.div`
	position: fixed;
	top: 55px; // the menu is 55px high
	left: 50vw;
	z-index: 100;
	width: 100vw;
	max-width: ${theme.maxContentWidth}px;
	transform: translateX(-50%);
`;

const SVideoCloseButton = styled.button`
	background: transparent;
	border: none;
	position: absolute;
	width: 30px;
	cursor: pointer;
	top: 10px;
	right: 10px;
	z-index: 1;
`;

const SCloseSvg = styled(CloseSvg)`
	width: 100%;
`;
//#endregion
