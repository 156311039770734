import CinemaSelectorButton from "src/components/cinemaselector/CinemaSelectorButton";
import DateSelectorButton from "src/components/dateselector/DateSelectorButton";
import LocationSelectorButton from "src/components/locationselector/LocationSelectorButton";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';

//#region [Props]
type SelectorsProps = {
	className?: string;
	hideCinemaSelector?: boolean;
};
//#endregion

//#region [Component]
export default function Selectors({ className, hideCinemaSelector = false }: SelectorsProps) {
	return <div className={className}>
		<SButtons>
			<DateSelectorButton />
			<LocationSelectorButton />
			{!hideCinemaSelector && <CinemaSelectorButton />}
		</SButtons>
	</div>;
}
//#endregion

//#region [Styles]
const SButtons = styled.div`
	padding: 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@media ${theme.mq.desktop} {
		column-gap: 30px;
	}

`;
//#endregion