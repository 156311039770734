export default function getFraction(average: number | undefined | null, target: number): number {
	if (!average) {
		return 0;
	}
	if (average >= target) {
		return 1.0;
	}


	return Math.max(0, parseFloat((average - Math.floor(target - 1)).toFixed(1)));
}