import CloseSvg from "src/components/svg/CloseSvg";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from 'src/lib/styles/css';
import { useEffect, useRef, useState } from "react";

//#region [Props]
type FlyoutPanelProps = {
	children: React.ReactNode;
	onAfterCloseComplete?: () => void;
	isClosed?: boolean;
};
//#endregion

//#region [Component]
export default function FlyoutPanel({ children, onAfterCloseComplete, isClosed = false }: FlyoutPanelProps) {
	const [isOpen, setIsOpen] = useState(false);
	const isMounting = useRef(true);

	useEffect(() => {
		// start initial animation AFTER first render
		setIsOpen(true);
	}, []);

	useEffect(() => {
		if (!isMounting.current && onAfterCloseComplete && !isOpen) {
			const id = setTimeout(() => onAfterCloseComplete(), 550);
			return () => {
				clearTimeout(id);
			};
		}
		isMounting.current = false;
	}, [onAfterCloseComplete, isOpen]);

	useEffect(() => {
		if (isClosed) {
			setIsOpen(false);
		}
	}, [isClosed]);

	return (
		<SFlyoutPanel $isOpen={isOpen}>
			<SInteractionContainer $isOpen={isOpen}>
				<SCloseButton onClick={() => setIsOpen(false)}><CloseSvg /></SCloseButton>
				{children}
			</SInteractionContainer>
		</SFlyoutPanel>
	);
}
//#endregion

//#region [Styles]
type SPanelProps = {
	$isOpen: boolean;
};

const SFlyoutPanel = styled.div<SPanelProps>`
	position: fixed;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	@supports (backdrop-filter: blur(3px)) {
		backdrop-filter: blur(0);
		transition: 0.5s backdrop-filter;
		${props => props.$isOpen ? css`
			backdrop-filter: blur(3px);

			@media ${theme.mq.desktop} {
				backdrop-filter: blur(8px);
			}
		`: null}

	}
	@supports not (backdrop-filter: blur(3px)) {
		background-color: ${theme.bgColor}00; // add 0 opacity

		transition: 0.5s background-color;
		${props => props.$isOpen ? css`
			background-color: var(--backgroundalpha);
		`: null}

	}

	z-index: 2000;
`;



const SInteractionContainer = styled.div<SPanelProps>`
	border-radius: var(--panel-borderradius) var(--panel-borderradius) 0 0;
	background-color: var(--backgroundcolor);
	position: absolute;
	transition: 0.5s transform;
	transform: translateY(100%);
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	padding: ${theme.bottomMenuHeight} calc(var(--page-padding) * 4);

	${props => props.$isOpen ? css`
		transform: translateY(0);
	`: null}

	@media ${theme.mq.desktop} {
		border-radius: var(--panel-borderradius);
		top: 50vh;
		left: 50vw;
		opacity: 0;
		transition: 0.5s opacity;
		width: 100vw;
		max-width: 500px;

		transform: translate(-50%, -50%);

		${props => props.$isOpen ? css`
			top: 50vh;
			opacity: 1;
		`: null}
	}

	color: var(--textcolor);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const SCloseButton = styled.button`
	background: none;
	padding: 0;
	border: none;
	position: absolute;
	top: calc(var(--page-padding) * 2);
	right: calc(var(--page-padding) * 2);
`;

//#endregion