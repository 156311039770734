import { useUserContext } from "src/lib/contexts/UserContext";
import IMovieReview from "src/lib/filmwebid/IMovieReview";
import useUserApi from "src/lib/filmwebid/useUserApi";
import { useCallback, useEffect, useReducer } from "react";


export default function useFilmReviewList(edi: string | null | undefined, streamingId: number | null | undefined): [reviews: IMovieReview[], totalReviews: number, isWorking: boolean, fetchNext: (numToFetch: number) => void] {
	const fetcher = useUserApi();

	const [state, dispatch] = useReducer(reducer, INIT_STATE);
	const authContext = useUserContext();

	useEffect(() => {
		// load initial data
		let abortController: AbortController | null = new AbortController();
		dispatch({ type: "startWork" });
		let startIndex = state.startIndex;
		if (edi !== state.edi || streamingId !== state.streamingId) {
			startIndex = 0;
		}

		const _getWrittenReviews: Promise<IMovieReviewsResponse> = (async () => {
			let fetchParams = {
				edi: edi ?? null,
				streamingId: streamingId ?? null,
				startIndex,
				count: state.numToFetch
			};
			fetchParams = Object.entries(fetchParams).reduce((a: any, [k, v]) => (v == null ? a : (a[k] = v, a)), {});

			return await fetcher("/api/Movie/MovieReviews", {
				method: "GET",
				signal: abortController.signal,
				allowAnon: true,
				params: fetchParams
			});

		})();

		let _getLikedReviews: Promise<string[]> | null = null;
		if (authContext.isAuthenticated) {
			_getLikedReviews = (async () => {
				return await fetcher("/api/Reviews/GetLiked", {
					method: "GET",
					signal: abortController.signal,
					allowAnon: true,
					params: { edi: edi ?? null, streamingId: streamingId ?? null }
				});
			})();
		} else {
			// resolve to an empty array if the user is not authenticated
			_getLikedReviews = Promise.resolve([]);
		}

		Promise.all([_getWrittenReviews, _getLikedReviews]).then((values) => {
			const [reviewList, likedList] = values;
			const { reviews, totalReviews } = reviewList ?? {};
			reviews?.filter(r => likedList.includes(r.userId))?.forEach(r => {
				r.isLiked = true;
			});
			if (startIndex === 0) {
				dispatch({ type: 'set', reviews, totalReviews, startIndex, edi, streamingId });
			} else {
				dispatch({ type: 'set', reviews: [...state.reviews, ...(reviews ?? [])], totalReviews, startIndex, edi, streamingId });
			}


			dispatch({ type: "stopWork" });
			abortController = null;
		});

		return () => {
			if (abortController) {
				try {
					abortController.abort("");
				} catch (e) {

				}
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edi, streamingId, state.startIndex, state.refresh, state.numToFetch, authContext.isAuthenticated]);

	const fetchNext = useCallback((numToFetch: number) => {
		// get next window
		dispatch({ type: "next", numToFetch });

	}, []);

	return [state.reviews, state.totalReviews, state.isWorking, fetchNext];
}

const INITIAL_COUNT = 6;

type FilmReviewListState = {
	isWorking: boolean;
	startIndex: number;
	refresh: number;
	reviews: IMovieReview[];
	totalReviews: number;
	edi: string | null | undefined;
	streamingId: number | null | undefined;
	numToFetch: number;
};

const INIT_STATE: FilmReviewListState = {
	isWorking: false,
	startIndex: 0,
	refresh: 0,
	reviews: [],
	totalReviews: 0,
	edi: null,
	streamingId: null,
	numToFetch: INITIAL_COUNT
};

type ReviewListAction = StartWorkAction | StopWorkAction | SetReviewsAction | NextReviewsAction | ResetReviewsAction;

type StartWorkAction = {
	type: "startWork"
};

type StopWorkAction = {
	type: "stopWork"
};

type SetReviewsAction = {
	type: "set";
	reviews: IMovieReview[];
	totalReviews: number;
	startIndex: number;
	edi?: string | null;
	streamingId?: number | null;
}

type NextReviewsAction = {
	type: "next";
	numToFetch: number;
}

type ResetReviewsAction = {
	type: "reset";
	numToFetch: number;
}

interface IMovieReviewsResponse {
	totalReviews: number;
	reviews: IMovieReview[];
}



function reducer(state: FilmReviewListState, action: ReviewListAction): FilmReviewListState {
	switch (action.type) {
		case 'startWork':
			return { ...state, isWorking: true };
		case 'stopWork':
			return { ...state, isWorking: false };
		case 'set':
			return { ...state, reviews: action.reviews, totalReviews: action.totalReviews, startIndex: action.startIndex, edi: action.edi, streamingId: action.streamingId };
		case 'next':
			return { ...state, refresh: (new Date()).getTime(), startIndex: state.startIndex + INITIAL_COUNT, isWorking: true, numToFetch: action.numToFetch }
		case 'reset':
			return INIT_STATE;
		default:
			return state;
	}
}