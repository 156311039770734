import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';

//#region [Props]
type MissingImageProps = {
	missingText?: string;
	aspect: string;
	className?: string;
};
//#endregion

//#region [Component]
export default function MissingImage({ missingText = "Bilde mangler", aspect, className }: MissingImageProps) {
	return <SMissingImage style={{ aspectRatio: aspect }} className={className}>
		<SMissingSvg viewBox="0 0 200 20">
			<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{missingText}</text>
		</SMissingSvg>
	</SMissingImage>;
}
//#endregion

//#region [Styles]
const SMissingImage = styled.div`
	background: ${theme.missingPoster};
	position: relative;
	box-sizing: border-box;
`;

const SMissingSvg = styled.svg`display: block;
	position: absolute;
	top: 50%;
	width: 100%;
	fill: #f8bdd2;
	transform: translate(0, -50%);
`

//#endregion