import { SRatingButton, SRatingContainer, SRatingStar } from "src/components/filmwebid/CSSPanelStyles";
import FlyoutPanel from "src/components/flyoutpanel/FlyoutPanel";
import StarSvg from "src/components/svg/StarSvg";
import { IMovieStats } from "src/lib/filmwebid/IMovieActivity";
import getFraction from "src/lib/filmwebid/getFraction";
import useRating from "src/lib/filmwebid/useRating";
import styled from 'src/lib/styles/css';
import { useCallback } from "react";

//#region [Props]
type RatingPanelProps = {
	onClose: (doUpdate: boolean) => void;
	edi?: string;
	streamingId?: number;
	userRating?: number;
	hasUserReview?: boolean;
	movieStats?: IMovieStats;
};
//#endregion

//#region [Component]
export default function RatingPanel({ edi, streamingId, userRating, onClose, hasUserReview = false, movieStats }: RatingPanelProps) {
	const [rater, isSavingRating, rating, isDoneSavingRating] = useRating({ edi, streamingId, initialValue: userRating });

	const toggleRating = useCallback((newRating: number, oldRating?: number) => {
		// if the user has rated before, clicking the same rating is the same as un-rating the movie
		if (newRating === oldRating) {
			// the user wants to un-rate. Only allow this if there is no review
			if (!hasUserReview) {
				rater(0);
			}
		} else {
			rater(newRating);
		}
	}, [rater, hasUserReview]);

	return (
		<FlyoutPanel onAfterCloseComplete={() => onClose(rating !== userRating)} isClosed={isDoneSavingRating}>
			<h3>{userRating ? "Din vurdering" : "Gi din vurdering:"}</h3>
			<SRatingContainer>
				<SRatingButton onClick={() => toggleRating(1, rating)} disabled={isSavingRating}><SRatingStar selected={(rating ?? 0) >= 1} /></SRatingButton>
				<SRatingButton onClick={() => toggleRating(2, rating)} disabled={isSavingRating}><SRatingStar selected={(rating ?? 0) >= 2} /></SRatingButton>
				<SRatingButton onClick={() => toggleRating(3, rating)} disabled={isSavingRating}><SRatingStar selected={(rating ?? 0) >= 3} /></SRatingButton>
				<SRatingButton onClick={() => toggleRating(4, rating)} disabled={isSavingRating}><SRatingStar selected={(rating ?? 0) >= 4} /></SRatingButton>
				<SRatingButton onClick={() => toggleRating(5, rating)} disabled={isSavingRating}><SRatingStar selected={(rating ?? 0) >= 5} /></SRatingButton>
			</SRatingContainer>
			<h3>Gjennomsnitt:</h3>
			<SAverageContainer>
				<StarSvg fractionSelected={getFraction(movieStats?.ratingAverage, 1)} />
				<StarSvg fractionSelected={getFraction(movieStats?.ratingAverage, 2)} />
				<StarSvg fractionSelected={getFraction(movieStats?.ratingAverage, 3)} />
				<StarSvg fractionSelected={getFraction(movieStats?.ratingAverage, 4)} />
				<StarSvg fractionSelected={getFraction(movieStats?.ratingAverage, 5)} />
			</SAverageContainer>
			<div>{movieStats?.ratingAverage ? `Snitt ${movieStats?.ratingAverage?.toFixed(1) || 0} basert på ${movieStats?.ratingCount || 0} vurderinger` : "Så langt har ingen gitt vurdering."} </div>

		</FlyoutPanel>
	);
}
//#endregion

//#region [Styles]
const SAverageContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10%;
	width: 100%;
	margin-bottom: 2vh;
`;
//#endregion